define("affinio/models/reports/-base", ["exports", "ember-data", "affinio/utils/affinio", "moment", "affinio/utils/constants/date-formats", "affinio/utils/data/array-attr", "affinio/utils/get-with-default", "d3v5"], function (_exports, _emberData, _affinio, _moment, _dateFormats, _arrayAttr, _getWithDefault, _d3v) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var attr = _emberData.default.attr;
  var Model = _emberData.default.Model;
  var _default = _exports.default = Model.extend({
    ajax: Ember.inject.service(),
    user: Ember.inject.service(),
    path: Ember.inject.service(),
    graph: Ember.inject.service(),
    taxos: Ember.inject.service(),
    // Base attributes
    name: attr('string'),
    socialNetwork: attr('string'),
    taxonomyID: attr('string'),
    notes: attr(),
    state: attr('string'),
    stateDetails: attr('string'),
    dateCreated: attr('date'),
    dateUpdated: attr('date'),
    dateFinished: attr('date'),
    numberOfClusters: attr('number'),
    isCustom: Ember.computed.equal('socialNetwork', 'Custom'),
    // Sharing
    creator: attr(),
    accounts: attr(),
    // Accounts must be an attr here, or we will lose shareinfo when it overwrites the account model
    companies: attr(),
    folders: attr(),
    groups: attr(),
    forceEditRights: Ember.computed('user.account', function () {
      if (Ember.get(this, 'user.account.isAffinio')) {
        return true;
      }
      // Company admin and report creator is their company
      else if (Ember.get(this, 'user.account.isAdmin') && Ember.get(this, 'user.company.id') === Ember.get(this, 'creator.company.id')) {
        return true;
      } else {
        return false;
      }
    }),
    reportEditPermissions: Ember.computed.or('accountEdit', 'companyEdit', 'folderEdit', 'groupEdit', 'forceEditRights'),
    notViewerSeat: Ember.computed.not('user.account.isViewerSeat'),
    canEdit: Ember.computed.and('notViewerSeat', 'reportEditPermissions', 'notReaggregating'),
    // Versions
    version: attr({
      defaultValue: 0
    }),
    _versions: _arrayAttr.default,
    isNotFirstVersion: Ember.computed.gt('currentVersion', 0),
    versions: Ember.computed('_versions', 'version', 'dateUpdated', 'numberOfMembers.response.value', function () {
      var _versions = Ember.get(this, '_versions');
      if (!_versions.length) {
        return _versions;
      } else {
        if (_versions.findBy('version', Ember.get(this, 'version'))) {
          return _versions;
        } else {
          return [{
            version: Ember.get(this, 'version'),
            dateUpdated: Ember.get(this, 'dateUpdated'),
            dateFinished: Ember.get(this, 'dateFinished'),
            numberOfMembers: Ember.get(this, 'numberOfMembers.response.value')
          }].concat(_toConsumableArray(_versions));
        }
      }
    }),
    // Taxonomy
    _taxonomy: attr(),
    taxonomy: Ember.computed('_taxonomy.id', 'taxos.taxonomies.[]', function () {
      if (Ember.get(this, 'socialNetwork') !== 'Custom') {
        return Ember.get(this, 'store').peekRecord('taxonomy', Ember.get(this, 'socialNetwork'));
      } else {
        if (!Ember.get(this, '_taxonomy.id')) {
          // TODO: Peerlogix hack
          Ember.set(this, '_taxonomy',
          // eslint-disable-line ember/no-side-effects
          {
            id: '822bff1a-5bee-49d0-999c-ba1e1e1d9494'
          });
          // set(this, 'taxonomyID', '6ab20e8d-b928-4b48-b4c2-908898f68bf1'); // TODO: Disney hack
        }
        // This isn't actually used, but the computed won't refire if we don't get its dependent key
        Ember.get(this, 'taxos.taxonomies');
        return Ember.get(this, 'store').peekRecord('taxonomy', Ember.get(this, '_taxonomy.id'));
      }
    }),
    showCompare: Ember.computed('metricDefinitions', function () {
      return !!Ember.get(this, 'metricDefinitions').filterBy('comparable').length;
    }),
    platformSchema: Ember.computed.alias('taxonomy.schema'),
    metricDefinitions: Ember.computed('taxonomy.metricDefinitions', function () {
      return (0, _getWithDefault.default)(this, 'taxonomy.metricDefinitions', []).reject(function (metric) {
        return Ember.get(metric, 'label').includes('.');
      });
    }),
    // Keep track of when this report was loaded in session for macro-menu recents
    sessionLoadedTime: attr(),
    // Computeds
    recordsMap: Ember.computed('recordsCollection.{id,loaded}', function () {
      // JSON doesn't have a Map() type, so we iterate over the passed array to make one.
      // This turns out to be pretty darn fast though!
      // the recordsMap idea, generally: Viewing metric instances in a normalized way requires us to see those instances
      // across _all_ clusters. However, the graphQL paradigm doesn't like loading unnecessary data
      // -- we don't need to know everything about every cluster.
      // So, to provide the normalized values for each cluster, we provide at report level and hydrate into metric-visualization.js

      // Create the map
      var recordsMap = new Map();
      if (Ember.get(this, 'recordsCollection')) {
        // console.log('hm', get(this, 'recordsCollection'));
        Ember.get(this, 'recordsCollection.response').forEach(function (metric) {
          return /* eslint-disable */(
            // js map
            recordsMap.set(metric.label, metric.values)
          );
        }
        /* eslint-enable */);

        // Create submaps
        recordsMap.forEach(function (metric, key) {
          var subMap = new Map();
          /* eslint-disable */
          // js map
          metric.forEach(function (instance) {
            return subMap.set(instance.label, instance.values);
          });
          recordsMap.set(key, subMap);
          /* eslint-enable */
        });
      }
      return recordsMap;
    }),
    recordsArray: Ember.computed('recordsMap.size', function () {
      // console.time('recordsArray');
      // let needsRatios = get(this, 'socialNetwork') !== 'Custom';
      var needsRatios = true; // TODO
      var recordsMap = Ember.get(this, 'recordsMap');
      // let influencers = get(this, 'clusters').mapBy('interests.response').flatten().uniqBy('networkID');
      // console.log('influencers?', influencers, recordsMap.size, recordsMap);
      var thing = Array.from(recordsMap).map(function (x) {
        return Array.from(x[1]).map(function (y) {
          var totalValue;
          if (needsRatios) {
            totalValue = y[1].mapBy('normalizedScore').reduce(function (m, n) {
              return m + n;
            });
          }
          if (x[0] !== 'locationInterests') {
            return {
              metric: x[0],
              label: y[0],
              value: y[1].mapBy('value').reduce(function (m, n) {
                return m + n;
              }),
              records: needsRatios ? y[1].map(function (record) {
                Ember.set(record, 'normalizedRatio', Ember.get(record, 'normalizedScore') / totalValue); // eslint-disable-line ember/no-side-effects
                return record;
              }) : y[1],
              normalizedRatios: needsRatios ? y[1].map(function (x) {
                return Ember.get(x, 'normalizedScore') / totalValue;
              }) : y[1].mapBy('normalizedRatio'),
              id: "".concat(x[0], "_").concat(y[0]),
              icon: null
            };
          }
        });
      }).flatten().compact();
      // console.timeEnd('recordsArray');
      return thing;
    }),
    notMonitoredReport: Ember.computed.not('monitoredInformation'),
    monitoredDateUpdated: Ember.computed('dateFinished', function () {
      if (Ember.get(this, 'dateFinished')) {
        return Ember.get(this, 'dateFinished');
      } else {
        return Ember.get(this, 'dateCreated');
      }
    }),
    // or('monitoredInformation.lastUpdate', 'dateCreated'),
    // TODO dont use path
    currentDateStart: Ember.computed('originalReportPeriod', 'path.currentVersion', function () {
      var currentVersion = parseInt(Ember.get(this, 'path.currentVersion'));
      var date;
      if (currentVersion || currentVersion === 0) {
        if (currentVersion === 0 && !Ember.get(this, 'versions.length')) {
          date = Ember.get(this, 'originalReportPeriod.start');
        } else {
          date = Ember.get(this, 'versions').findBy('version', currentVersion);
          date = date && (date.dateFinished || date.dateUpdated);
          if (date) {
            date = (0, _moment.default)(date).subtract(29, 'days');
          }
        }
      } else {
        date = Ember.get(this, 'originalReportPeriod.start');
      }
      return (0, _dateFormats.fullShortMonth)(date);
    }),
    // TODO dont use path
    currentDateEnd: Ember.computed('originalReportPeriod', 'path.currentVersion', function () {
      var currentVersion = parseInt(Ember.get(this, 'path.currentVersion'));
      var date;
      if (currentVersion || currentVersion === 0) {
        if (currentVersion === 0 && !Ember.get(this, 'versions.length')) {
          date = Ember.get(this, 'originalReportPeriod.end');
        } else {
          date = Ember.get(this, 'versions').findBy('version', currentVersion);
          date = date && (date.dateFinished || date.dateUpdated);
        }
      } else {
        date = Ember.get(this, 'originalReportPeriod.end');
      }
      return (0, _dateFormats.fullShortMonth)(date);
    }),
    originalReportPeriod: Ember.computed('dateFinished', 'dateUpdated', function () {
      return {
        start: (0, _moment.default)(Ember.get(this, 'dateFinished')).subtract(29, 'days'),
        end: (0, _moment.default)(Ember.get(this, 'dateFinished'))
      };
    }),
    currentReportPeriod: Ember.computed('currentDateStart', 'currentDateEnd', function () {
      return {
        start: (0, _moment.default)(Ember.get(this, 'currentDateStart')),
        end: (0, _moment.default)(Ember.get(this, 'currentDateEnd'))
      };
    }),
    previousReportPeriod: Ember.computed('currentReportPeriod', 'versions', 'currentVersion', function () {
      var version = Ember.get(this, 'versions').findBy('version', Ember.get(this, 'currentVersion') - 1);
      var date = version && (version.dateFinished || version.dateUpdated);
      return {
        start: (0, _moment.default)(date).subtract(30, 'days'),
        end: (0, _moment.default)(date)
      };
    }),
    isReaggregating: Ember.computed.equal('state', 'reaggregating'),
    notReaggregating: Ember.computed.not('isReaggregating'),
    hasRemovedClusters: Ember.computed('_clusters', '_clusters.@each.isClusterRemoved', function () {
      // _clusters returns all clusters in a report including removed clusters
      // not all networks have _clusters, use an empty array as default.
      var clusters = (0, _getWithDefault.default)(this, '_clusters', []);
      return clusters.any(function (cluster) {
        return Ember.get(cluster, 'isClusterRemoved');
      });
    }),
    noRemovedClusters: Ember.computed.not('hasRemovedClusters'),
    hasMergedClusters: Ember.computed('_clusters', '_clusters.@each.mergedInto', function () {
      // _clusters returns all clusters in a report including removed clusters
      // not all networks have _clusters, use an empty array as default.
      var clusters = (0, _getWithDefault.default)(this, '_clusters', []);
      return clusters.any(function (cluster) {
        return Ember.get(cluster, 'mergedInto');
      });
    }),
    noMergedClusters: Ember.computed.not('hasMergedClusters'),
    clustersLite: Ember.computed('clusters', 'clusters.@each.{name,suffix,isHidden}', function () {
      return Ember.get(this, 'clusters').map(function (n) {
        return {
          id: Ember.get(n, 'id'),
          name: Ember.get(n, 'name'),
          index: Ember.get(n, 'index'),
          // TODO get index somehow
          suffix: Ember.get(n, 'suffix'),
          isHidden: Ember.get(n, 'isHidden')
        };
      });
    }),
    allClusters: Ember.computed('clusters', 'clusterAggregation', function () {
      // clusterAggregation.content gets the resolved model
      // .compact incase you haven't loaded the report yet and clusters aren't a thing
      return [Ember.get(this, 'clusterAggregation.content')].concat(_toConsumableArray(Ember.get(this, 'clusters').toArray())).compact();
    }),
    clusterMemberCounts: Ember.computed('clusters.@each.{id,name}', 'clusterNumberOfMembers.@each.loaded', function () {
      return Ember.get(this, 'clusters').map(function (cluster) {
        return Object.create({
          'id': Ember.get(cluster, 'id'),
          'label': Ember.get(cluster, 'name'),
          'value': Ember.get(cluster, 'numberOfMembers.response.value')
        });
      });
    }),
    clusterIdNameHash: Ember.computed('clusters.@each.{name,id}', function () {
      return Ember.get(this, 'clusters').reduce(function (hash, cluster) {
        hash[Ember.get(cluster, 'id')] = Ember.get(cluster, 'name');
        return hash;
      }, {});
    }),
    isContentReport: Ember.computed.notEmpty('filters.content_query'),
    everyClusterDensity: Ember.computed.mapBy('clusters', 'density'),
    everyClusterDensityResponse: Ember.computed('everyClusterDensity.@each.loaded', function () {
      return Ember.get(this, 'everyClusterDensity').compact().mapBy('response').compact();
    }),
    isCreator: Ember.computed('user.account.id', 'creator.id', function () {
      return Ember.get(this, 'user.account.id') === Ember.get(this, 'creator.id');
    }),
    hasLurkers: Ember.computed('clusters.@each.lurkerPercentage', function () {
      return Ember.get(this, 'clusters').toArray().some(function (n) {
        return Ember.get(n, 'lurkerPercentage');
      });
    }),
    hasInterconnectivity: Ember.computed('everyClusterDensityResponse.@each.value', function () {
      return Ember.get(this, 'everyClusterDensityResponse').toArray().some(function (n) {
        return Ember.get(n, 'value');
      });
    }),
    postType: Ember.computed('socialNetwork', function () {
      return Ember.get(this, 'socialNetwork') === 'Twitter' ? 'Tweet' : 'Post';
    }),
    hasExclusionFilters: Ember.computed(function () {
      if (!Ember.get(this, 'filters')) {
        return [];
      }
      return (0, _affinio.hasExclusions)(Ember.get(this, 'filters'));
    }),
    notHasExclusionFilters: Ember.computed.not('hasExclusionFilters'),
    hasRecommendedNames: Ember.computed('clusters.@each.recommendedName', function () {
      return !!Ember.get(this, 'clusters').any(function (c) {
        return Ember.get(c, 'recommendedName');
      });
    }),
    hasAudienceGrowth: Ember.computed('filters', function () {
      return !(Ember.get(this, 'filters.contentQuery') || Ember.get(this, 'filters.fileURL'));
    }),
    hasMoreThanTwo: Ember.computed.gte('filters.followersOf.length', 2),
    hasLessThanTwelve: Ember.computed.lte('filters.followersOf.length', 12),
    hasCompetitive: Ember.computed('filters.followersIntersection', 'hasMoreThanTwo', 'hasLessThanTwelve', function () {
      return !Ember.get(this, 'filters.followersIntersection') && Ember.get(this, 'hasMoreThanTwo') && Ember.get(this, 'hasLessThanTwelve');
    }),
    // Used for setting up normalization multipliers / clusterRatios
    clusterNumberOfMembers: Ember.computed.mapBy('clusters', 'numberOfMembers'),
    clusterMembersLoaded: Ember.computed('clusterNumberOfMembers.@each.loaded', function () {
      return Ember.get(this, 'clusterNumberOfMembers').every(function (n) {
        return Ember.get(n, 'loaded');
      });
    }),
    clusterMembersTotal: Ember.computed('clusterNumberOfMembers.@each.loaded', function () {
      return Ember.get(this, 'clusterNumberOfMembers').reduce(function (total, cluster) {
        return total + Ember.get(cluster, 'response.value');
      }, 0);
    }),
    hasMoreThanSingleCluster: Ember.computed.gte('_clusters.length', 2),
    averageNumberOfMembers: Ember.computed('clusterMembersTotal', function () {
      return Ember.get(this, 'clusterMembersTotal') / Ember.get(this, 'clusters.length');
    }),
    competitiveOrder: Ember.computed('hasCompetitive', 'clusterAggregation._breakdown.loaded', function () {
      var _this = this;
      if (Ember.get(this, 'hasCompetitive') && Ember.get(this, 'clusterAggregation._breakdown.loaded')) {
        var isModernBreakdown = Ember.get(this, 'clusterAggregation.modernBreakdown');
        return Ember.get(this, 'clusterAggregation._breakdown.response').filter(function (item) {
          return item["".concat(isModernBreakdown ? 'users' : 'screenName')].length === 1;
        } // the breakdown is big, includes cart. permutations -- go for only the singulars.
        ).sortBy('size').reverse().map(function (n, i) {
          return {
            label: isModernBreakdown ? n.users[0].screenName : n.screenNames[0],
            id: n.users[0].id ? n.users[0].id : "".concat(Ember.get(_this, 'id'), "_").concat(i),
            iter: i + 1
          };
        });
      } // if hasCompetitive
    }),
    hasDemographics: Ember.computed('clusterAggregation.demographics.{loaded,response}', function () {
      var demographics = Ember.get(this, 'clusterAggregation.demographics.response');
      if (Ember.get(this, 'clusterAggregation.demographics.loaded') && demographics) {
        return Object.values(demographics) && !!Object.values(demographics).compact().length;
      } else {
        return false;
      }
    }),
    // Used as a dynamic dependency for audienceSummary (among other things, in the future)
    // lists out metrics' that are desired to be aggregated in clusters/-base
    // mixins/affinio-module cares about this, in init(), by getting audienceSummary's dynamicDependency
    aggregateMetrics: Ember.computed('clusters.@each.{aggregateMetrics,aggregateMetricInstances}', function () {
      Ember.get(this, 'graph').loadMetrics(Ember.get(this, 'allClusters').mapBy('aggregateMetricInstances').flatten());
      return Ember.get(this, 'allClusters').mapBy('aggregateMetrics').flatten().uniqBy('value');
    }),
    // If the report taxonomy is so vast that metrics can be hard to find,
    // let's help the user out and give them a cute little search field.
    // Yields in cluster-nav.hbs
    needsMetricLookup: Ember.computed.gt('metricDefinitions.length', 50),
    recalculateTraits: function recalculateTraits() {
      if (Ember.get(this, 'taxonomy.meta.traits.driver') !== 'metricDefinitions') {
        Ember.get(this, 'taxonomy.metricDefinitions').forEach(function (md) {
          return md.toggleProperty('referencingModulesTrigger');
        });
        Ember.get(this, 'allClusters').forEach(function (cluster) {
          return cluster.toggleProperty('traitsTrigger');
        });
      }
    },
    // The following both set in baseline-summary.js
    // Eventual TODO: handle these pure-functionally, without external dependency on being set from a component.
    clusterBaselineScale: Ember.computed('averageClusterBaselineScore', function () {
      var averageBaselineScore = Ember.get(this, 'averageClusterBaselineScore');
      var clusterBaselineCeiling = _.max(Ember.get(this, 'clusters').mapBy('soWhat'));
      return _d3v.default.scaleLinear().domain([0, averageBaselineScore, clusterBaselineCeiling]).range(['#c00', '#ffffff', '#4DB930']);
    }),
    traitsBaselineScale: Ember.computed('averageTraitBaselineScore', 'averageClusterBaselineScore', function () {
      var averageBaselineScore = Ember.get(this, 'averageTraitBaselineScore');
      var baselinedTraitCeiling = _.max(Ember.get(this, 'clusters').mapBy('traits.data').flatten().filter(function (x) {
        return x.saturation > 0.01;
      }).mapBy('timesMoreLikelyThanBaseline').compact());
      // if (get(this, 'clusterAggregation.baselinedTraits.length')) {
      return _d3v.default.scaleLog().domain([0.01, averageBaselineScore, baselinedTraitCeiling]).range(['#c00', '#ffffff', '#4DB930']);
      // }
    }),
    noBaselineOverlap: Ember.computed('baselineReport', 'clusters.@each.soWhat', function () {
      if (Ember.get(this, 'baselineReport')) {
        return Ember.get(this, 'clusters').every(function (cluster) {
          return Ember.get(cluster, 'soWhat') === 0;
        });
      }
    }),
    clusterOverlap: Ember.computed('recordsMap.size', 'clusterMembersLoaded', 'clusters.@each.suffix', function () {
      var recordsMap = Ember.get(this, 'recordsMap');
      var clustersLoaded = Ember.get(this, 'clusterMembersLoaded');
      if (recordsMap.size !== 0 && clustersLoaded) {
        // console.time('correlationMatrix');
        var clusters = Ember.get(this, 'clusters');
        var clusterMap = {};
        var instanceCount = 0;
        var _iterator = _createForOfIteratorHelper(recordsMap.values()),
          _step;
        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var metricMap = _step.value;
            instanceCount += metricMap.size;
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
        var _iterator2 = _createForOfIteratorHelper(clusters.toArray()),
          _step2;
        try {
          var _loop = function _loop() {
            var cluster = _step2.value;
            var suffix = Ember.get(cluster, 'suffix');
            clusterMap[suffix] = {};
            var _iterator3 = _createForOfIteratorHelper(clusters.without(cluster)),
              _step3;
            try {
              var _loop2 = function _loop2() {
                var otherCluster = _step3.value;
                var otherSuffix = Ember.get(otherCluster, 'suffix');
                clusterMap[suffix][otherSuffix] = 0;
                var inverse = Ember.get(clusterMap, "".concat(otherSuffix, ".").concat(suffix));
                if (Ember.isEmpty(inverse)) {
                  var _iterator4 = _createForOfIteratorHelper(recordsMap.values()),
                    _step4;
                  try {
                    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
                      var _metricMap = _step4.value;
                      var _iterator5 = _createForOfIteratorHelper(_metricMap.values()),
                        _step5;
                      try {
                        for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                          var instance = _step5.value;
                          var clusterInstances = instance.filter(function (n) {
                            return [suffix, otherSuffix].includes(n.cluster) && n.value > 0;
                          }).length === 2;
                          if (clusterInstances) {
                            clusterMap[suffix][otherSuffix]++;
                          }
                        }
                      } catch (err) {
                        _iterator5.e(err);
                      } finally {
                        _iterator5.f();
                      }
                    }
                  } catch (err) {
                    _iterator4.e(err);
                  } finally {
                    _iterator4.f();
                  }
                } else {
                  clusterMap[suffix][otherSuffix] = inverse;
                }
              };
              for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
                _loop2();
              }
            } catch (err) {
              _iterator3.e(err);
            } finally {
              _iterator3.f();
            }
          };
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            _loop();
          }

          // Get the data ready for our component
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
        var suffixes = clusters.mapBy('suffix');
        var data = suffixes.map(function (suffix) {
          return suffixes.map(function (_suffix) {
            return suffix === _suffix ? 1 : clusterMap[suffix][_suffix] / instanceCount;
          });
        });
        var rawData = data.map(function (n, i) {
          return n.slice(0, i);
        }).flatten();
        // console.timeEnd('correlationMatrix');
        return {
          data: data,
          average: rawData.reduce(function (c, a) {
            return a += c;
          }, 0) / rawData.length,
          max: _.max(rawData)
        };
      }
    })
  });
});